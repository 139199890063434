// sort name and sort rank works

import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  Pressable,
  ScrollView,
  ActivityIndicator,
  TextInput,
} from "react-native";
import { database, auth  } from "../componenets/firebase";
import {
  collectionGroup,
  query,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  where,
  writeBatch,
  collection,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import PointSystemModal from './ModalPointSystem'; // Adjust the path if needed
import { MaterialIcons } from "@expo/vector-icons"; // Import MaterialIcons from Expo



//import { LinearGradient } from 'expo-linear-gradient'; // For background gradient (Expo's library)

const Results = ({ navigation, route }) => {
  const { mainGame, mainGame : mainGameId, TheGameId, gameDate } = route.params;
  const [playerData, setPlayerData] = useState([]);
  const [gameSettings, setGameSettings] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [sortBy, setSortBy] = useState("Rank"); // State to track sorting criteria (default is Name)
  const originalData = useRef([]); // Store original data for sorting, using useRef to preserve data between renders
  const [scoreHome, setScoreHome] = useState(""); // For Home team score
  const [scoreAway, setScoreAway] = useState(""); // For Away team score
  const [teamSelections, setTeamSelections] = useState({}); // Store team selection per userId
  const [modalVisible, setModalVisible] = useState(false); // Modal state

  const homeCount = playerData.filter(player => teamSelections[player.userId] === "Home").length;
  const awayCount = playerData.filter(player => teamSelections[player.userId] === "Away").length;
  const absentCount = playerData.filter(player => teamSelections[player.userId] === "Absent").length;
  const [isAdmin, setIsAdmin] = useState(false); // Add isAdmin state

  

  //const [pointsEnabled, setPointsEnabled] = useState(false);


  // UseEffects

  useEffect(() => {
    const fetchIsAdminStatus = async () => {
      try {
        const mainGameDoc = doc(database, "MainGame", mainGameId);
        const mainGameSnapshot = await getDoc(mainGameDoc);
        const { memberOfGame } = mainGameSnapshot.data();

        const currentUser = auth.currentUser;

        if (currentUser) {
          const currentUserId = currentUser.uid;
          const currentUserData = memberOfGame.find(
            (member) => member.uid === currentUserId
          );

          if (currentUserData) {
            const adminStatus = currentUserData.isAdmin;
            setIsAdmin(adminStatus === true);
          }
        }
      } catch (error) {
        console.error("Error in fetchIsAdminStatus:", error);
      }
    };

    fetchIsAdminStatus();
  }, [mainGameId]);

   // Fetch game Name
   useEffect(() => {
    const fetchGameSettings = async () => {
      const gameSettingsRef = doc(database, "MainGame", mainGameId);
      const gameSettingsSnap = await getDoc(gameSettingsRef);
      if (gameSettingsSnap.exists()) {
        setGameSettings(gameSettingsSnap.data());
      }
    };
    fetchGameSettings();
  }, [mainGameId]);

  // update score in leaderboard
  useEffect(() => {
    const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
  
    const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const gameData = docSnapshot.data();
        setScoreHome(gameData.scoreHome || 0); // Update home score
        setScoreAway(gameData.scoreAway || 0); // Update away score
      }
    });
  
    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [mainGameId, TheGameId]);
  

  // Fetch game points settings
  // Fetch game settings / points and log them
useEffect(() => {
  const fetchGameSettings = async () => {
    try {
      const gameSettingsRef = doc(database, "MainGame", mainGameId);
      const gameSettingsSnap = await getDoc(gameSettingsRef);

      if (gameSettingsSnap.exists()) {
        const settingsData = gameSettingsSnap.data();
        setGameSettings(settingsData);
        
      
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching game settings:", error);
    }
  };

  fetchGameSettings();
}, [mainGameId, TheGameId]); // Dependencies for useEffect




  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        // Fetch game settings and score
        const gameSettingsRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
        const gameSettingsSnap = await getDoc(gameSettingsRef);
        if (gameSettingsSnap.exists()) {
          const gameData = gameSettingsSnap.data();
          setScoreHome(gameData.scoreHome || 0);  // Fetch and set home score
          setScoreAway(gameData.scoreAway || 0);  // Fetch and set away score
          console.log(`Fetched scores: Home - ${gameData.scoreHome}, Away - ${gameData.scoreAway}`);
        }
  
        // Fetch team selections
        const playersRef = collection(database, "MainGame", mainGameId, "TheGame", TheGameId, "Players");
        const currentGameSnapshot = await getDocs(playersRef);
  
        let selections = {};
        currentGameSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          selections[doc.id] = data.team || null; // Set team from Firestore or null if not set
          console.log(`Player ${doc.id} has team: ${data.team}`);

        });
  
        // Log fetched team selections
        console.log("Fetched team selections:", selections);
  
        // Update local state with team selections
        setTeamSelections(selections);
      } catch (error) {
        console.error("Error fetching game details:", error);
      }
    };
  
    fetchGameDetails();
  }, [mainGameId, TheGameId]);  // Trigger fetch on screen load
  


  //Trigger listener on screen load
  useEffect(() => {
    const subscribeToTeamSelections = () => {
      const playersRef = collection(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId,
        "Players"
      );

      // Set up a real-time listener with onSnapshot
      const unsubscribe = onSnapshot(playersRef, (snapshot) => {
        let selections = {};
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          selections[doc.id] = data.team || null; // Update team from Firestore
        });

        // Update local state with real-time team selections
        setTeamSelections(selections);
      });

      // Clean up listener on unmount
      return () => unsubscribe();
    };

    subscribeToTeamSelections();
  }, [mainGameId, TheGameId]); // Trigger listener on screen load

  useEffect(() => {
    const fetchTeamSelections = async () => {
      try {
        const playersRef = collection(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players"
        );
        const currentGameSnapshot = await getDocs(playersRef);

        let selections = {};
        currentGameSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          selections[doc.id] = data.team || null; // Set team from Firestore or null if not set
        });

        // Update local state with fetched team selections
        setTeamSelections(selections);
      } catch (error) {
        console.error("Error fetching team selections:", error);
      }
    };

    fetchTeamSelections();
  }, [mainGameId, TheGameId]); // Trigger fetch on screen load




  // Fetch game settings
  // useEffect(() => {
  //   const fetchAndSortPlayersByName = async () => {
  //     try {
  //       setLoading(true);
  
  //       // Step 1: Fetch all players across all games for the main game
  //       const allPlayersQuery = query(
  //         collectionGroup(database, "Players"),
  //         where("mainGameId", "==", mainGame) // Fetch players for the main game
  //       );
  
  //       const allPlayersSnapshot = await getDocs(allPlayersQuery); // Fetch data for all players
  //       console.log("Fetched all players:", allPlayersSnapshot.docs.length);
  //       let allPlayers = {};
  
  //       // Step 2: Process player data and filter those who are 'Going'
  //       allPlayersSnapshot.docs.forEach((doc) => {
  //         const data = doc.data();
  //         const playerKey = data.userId;
  
  //         // Only process players who are marked as "Going"
  //         if (data.value === "Going") {
  //           if (!allPlayers[playerKey]) {
  //             allPlayers[playerKey] = {
  //               Name: data.Name,
  //               email: data.email || "",
  //               userId: playerKey,
  //               totalPoints: 0,
  //               totalGF: 0,
  //               totalGA: 0,
  //               totalGD: 0,
  //               team: data.team || null,
  //             };
  //           }
  
  //           // Update player's cumulative stats
  //           if (data.totalPoints != null) {
  //             allPlayers[playerKey].totalPoints += data.totalPoints;
  
  //             if (data.team === "Home") {
  //               allPlayers[playerKey].totalGF += data.scoreHome || 0;
  //               allPlayers[playerKey].totalGA += data.scoreAway || 0;
  //             } else {
  //               allPlayers[playerKey].totalGF += data.scoreAway || 0;
  //               allPlayers[playerKey].totalGA += data.scoreHome || 0;
  //             }
  
  //             allPlayers[playerKey].totalGD =
  //               allPlayers[playerKey].totalGF - allPlayers[playerKey].totalGA;
  //           }
  //         }
  //       });
  
  //       // Step 3: Store and sort players by totalPointsBeforeTest and totalGDbeforeTest
  //       let allPlayersArray = Object.values(allPlayers); // Convert the object to an array

  //       // Sort by totalPointsBeforeTest and totalGDbeforeTest
  //       allPlayersArray = allPlayersArray.sort((a, b) => {
  //         if (a.totalPointsBeforeTest === b.totalPointsBeforeTest) {
  //           return b.totalGDbeforeTest - a.totalGDbeforeTest; // Sort by totalGD if totalPoints are equal
  //         }
  //         return b.totalPointsBeforeTest - a.totalPointsBeforeTest; // Sort by totalPoints
  //       });
  
  //       // Step 4: Store sorted players
  //       originalData.current = allPlayersArray;
  
  //       // Step 5: Fetch players for the specific TheGameId
  //       const playersRef = collection(
  //         database,
  //         "MainGame",
  //         mainGame,
  //         "TheGame",
  //         TheGameId,
  //         "Players"
  //       );
  //       const currentGameSnapshot = await getDocs(playersRef);
  
  //       let currentGamePlayers = [];
  
  //       currentGameSnapshot.docs.forEach((doc) => {
  //         const playerData = doc.data();
  //         const playerId = doc.id;
  
  //         // Only include players who are marked as "Going"
  //         if (playerData.value === "Going") {
  //           const playerInAllPlayers = allPlayersArray.find(
  //             (p) => p.userId === playerId
  //           );
  
  //           if (playerInAllPlayers) {
  //             currentGamePlayers.push(playerInAllPlayers); // Add player with cumulative stats
  //           }
  //         }
  //       });
  
  //       // Sort based on the selected criterion (by name)
  //       currentGamePlayers = currentGamePlayers.sort((a, b) =>
  //         a.Name.localeCompare(b.Name)
  //       );
  
  //       setPlayerData(currentGamePlayers); // Update state with current game players
  //     } catch (error) {
  //       console.error("Error fetching player data:", error);
  //     } finally {
  //       setLoading(false); // Hide loading spinner
  //     }
  //   };
  
  //   fetchAndSortPlayersByName();
  // }, [mainGame, TheGameId]);
  
  useEffect(() => {
    const fetchAndSortPlayersByPointsAndGDForDate = async () => {
      try {
        setLoading(true);
  
        // Step 1: Fetch players for the specific game (TheGameId represents the game on a specific date)
        const playersRef = collection(
          database,
          "MainGame",
          mainGame,
          "TheGame",
          TheGameId,
          "Players"
        );
  
        const playersSnapshot = await getDocs(playersRef); // Fetch data for players for the given game (date)
        let allPlayers = {};
  
        // Step 2: Process player data for the given date
        playersSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const playerKey = data.userId;
  
          // Only include players who are marked as "Going"
          if (data.value === "Going") {
            if (!allPlayers[playerKey]) {
              allPlayers[playerKey] = {
                Name: data.Name,
                email: data.email || "",
                userId: playerKey,
                totalPointsBeforeTest: data.totalPointsBeforeTest || 0,
                totalGDbeforeTest: data.totalGDbeforeTest || 0,
                team: data.team || null,
              };
            }
  
            // Log player's name and their points and GD for debugging
            console.log(`Player: ${data.Name}, totalPointsBeforeTest: ${data.totalPointsBeforeTest || 0}, totalGDbeforeTest: ${data.totalGDbeforeTest || 0}`);
          }
        });
  
        // Step 3: Sort players by totalPointsBeforeTest and totalGDbeforeTest for the given date
        let allPlayersArray = Object.values(allPlayers); // Convert the object to an array
  
        // Sort by totalPointsBeforeTest, totalGDbeforeTest, and Name
        allPlayersArray = allPlayersArray.sort((a, b) => {
          if (a.totalPointsBeforeTest === b.totalPointsBeforeTest) {
            if (a.totalGDbeforeTest === b.totalGDbeforeTest) {
              return a.Name.localeCompare(b.Name); // Sort by Name if both totalPointsBeforeTest and totalGDbeforeTest are equal
            }
            return b.totalGDbeforeTest - a.totalGDbeforeTest; // Sort by totalGDbeforeTest if totalPointsBeforeTest are equal
          }
          return b.totalPointsBeforeTest - a.totalPointsBeforeTest; // Sort by totalPointsBeforeTest
        });

  
        // Step 4: Store sorted players
        originalData.current = allPlayersArray;
  
        // Step 5: Update the player data for the current game
        setPlayerData(allPlayersArray); // Update state with sorted players
      } catch (error) {
        console.error("Error fetching player data:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };
  
    fetchAndSortPlayersByPointsAndGDForDate();
  }, [mainGame, TheGameId]);
  

  
  
  

  // Clear all "Home" and "Away" team selections
  const clearAllTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Reset team selections in local state
      setTeamSelections({});

      // Create a Firestore batch to clear team selections in Firestore
      const batch = writeBatch(database);

      playerData.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(playerRef, { team: null }, { merge: true });
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("All teams cleared successfully in batch");
    } catch (error) {
      console.error("Error clearing teams in Firestore:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Randomize and save teams
  const randomizeAndSaveTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Randomly assign teams
      const shuffledPlayers = [...playerData].sort(() => 0.5 - Math.random()); // Shuffle the players
      const updatedTeamSelections = {};
      const halfTeamSize = Math.ceil(shuffledPlayers.length / 2);

      shuffledPlayers.forEach((player, index) => {
        updatedTeamSelections[player.userId] =
          index < halfTeamSize ? "Home" : "Away";
      });

      // Update local state with random teams
      setTeamSelections(updatedTeamSelections);

      // Create a Firestore batch
      const batch = writeBatch(database);

      // Update Firestore with new team selections
      shuffledPlayers.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(
          playerRef,
          { team: updatedTeamSelections[player.userId] },
          { merge: true }
        );
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("Teams randomized and saved successfully.");
    } catch (error) {
      console.error("Error randomizing and saving teams:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Toggle (alternating) teams Home/Away
  const assignAlternatingTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Alternate teams between Home and Away
      const updatedTeamSelections = {};
      playerData.forEach((player, index) => {
        updatedTeamSelections[player.userId] =
          index % 2 === 0 ? "Home" : "Away";
      });

      // Update local state with alternating teams
      setTeamSelections(updatedTeamSelections);

      // Create a Firestore batch
      const batch = writeBatch(database);

      // Update Firestore with new alternating team selections
      playerData.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(
          playerRef,
          { team: updatedTeamSelections[player.userId] },
          { merge: true }
        );
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("Teams assigned in alternating Home/Away pattern.");
    } catch (error) {
      console.error("Error assigning teams in Firestore:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Function to update player's team in Firestore and state WITHOUT re-sorting, using background syncing
 // Function to update player's team in Firestore and state
 const updatePlayerTeam = async (userId, team) => {
  if (!userId || !mainGameId || !TheGameId) {
    console.error("Missing parameters for Firestore doc path");
    return;
  }

  console.log(`Updating team for userId: ${userId}, new team: ${team}`);

  // Firestore reference to the player document
  const playerRef = doc(
    database,
    "MainGame",
    mainGameId,
    "TheGame",
    TheGameId,
    "Players",
    userId
  );

  try {
    // Update Firestore with the selected team
    await setDoc(playerRef, { team: team }, { merge: true });

    // Update the local state with the latest team selection
    setTeamSelections((prev) => ({
      ...prev,
      [userId]: team,
    }));

    console.log(`Player team successfully updated in Firestore for userId: ${userId}`);
  } catch (error) {
    console.error("Error updating player team in Firestore:", error);
  }
};






  // save score logic:
  // Define the calculatePoints function
  // save score logic:
const calculatePoints = (
  scoreHome, 
  scoreAway, 
  playerTeam, 
  gameSettings, 
  fouls = 0, 
  isAbsent = false, 
  playerName // Added playerName parameter to log the player name
) => {
  // Default points configuration
  let drawPointsConfig = 0;
  let winPointsConfig = 1;
  let losePointsConfig = 0;
  let attendancePointsConfig = 0;
  let teamGoalPointsConfig = 0;
  let absentPointsConfig = 0;

  // Override default points if gameSettings is available and pointsEnabled is true
  if (gameSettings?.pointsEnabled) {
    drawPointsConfig = gameSettings.drawPoints ?? 0;
    winPointsConfig = gameSettings.winPoints ?? 1;
    losePointsConfig = gameSettings.losePoints ?? 0;
    attendancePointsConfig = gameSettings.attendancePoints ?? 0;
    teamGoalPointsConfig = gameSettings.TeenGoalPoints ?? 0;
    absentPointsConfig = gameSettings.absentPoints ?? 0;
  }

  // Log the playerTeam and playerName for debugging
  console.log(`Calculating points for ${playerName} (${playerTeam || 'Unknown Team'}) | Home: ${scoreHome}, Away: ${scoreAway}`);

  // If playerTeam is undefined or not properly passed
  if (!playerTeam) {
    console.error(`Error: playerTeam is not defined for ${playerName}.`);
    return;
  }

  let drawPoints = 0,
      winPoints = 0,
      losePoints = 0,
      attendancePoints = 0,
      teenGoalPoints = 0,
      absentPoints = 0,
      result = "";

  // Handle absent points directly if the player is absent
  if (isAbsent) {
    absentPoints = absentPointsConfig;
    console.log(`${playerName} is absent. Assigning absent points: ${absentPoints}`);
  } else {
    // Attendance points if either team has scored
    attendancePoints = (scoreHome > 0 || scoreAway > 0) ? attendancePointsConfig : 0;

    // Determine the game result for this player
    if (scoreHome === scoreAway) {
      drawPoints = drawPointsConfig;
      result = "draw"; // Game ended in a draw
      console.log(`Game ended in a draw for ${playerName}.`);
    } else if (playerTeam === "Home" && scoreHome > scoreAway) {
      winPoints = winPointsConfig;
      result = "win"; // Home team wins
      console.log(`${playerName}'s Home team won.`);
    } else if (playerTeam === "Away" && scoreAway > scoreHome) {
      winPoints = winPointsConfig;
      result = "win"; // Away team wins
      console.log(`${playerName}'s Away team won.`);
    } else {
      losePoints = losePointsConfig;
      result = "lost"; // Player's team lost
      console.log(`${playerName}'s team lost.`);
    }

    // Award Teen Goal Points if the player's team scored 10 or more goals
    if ((playerTeam === "Home" && scoreHome >= 10) || (playerTeam === "Away" && scoreAway >= 10)) {
      teenGoalPoints = teamGoalPointsConfig;
      console.log(`Awarding Teen Goal Points for ${playerName}: ${teenGoalPoints}`);
    }
  }

  // Total points calculation (attendance + win/draw/lose - fouls + absent points)
  const totalPoints = winPoints + drawPoints + attendancePoints + teenGoalPoints - fouls + absentPoints;

  // Log the result for each player
  console.log(`Player ${playerName} on team ${playerTeam} has result: ${result}`);
  console.log("Points Breakdown:");
  console.log(`WinPoints: ${winPoints}, DrawPoints: ${drawPoints}, LosePoints: ${losePoints}`);
  console.log(`AttendancePoints: ${attendancePoints}, TeenGoalPoints: ${teenGoalPoints}, Fouls: ${fouls}`);
  console.log(`TotalPoints: ${totalPoints}, Result: ${result}`);
  console.log("--------------------");  // Separator for better readability

  // Return the calculated points
  return {
    teenGoalPoints,
    drawPoints,
    winPoints,
    losePoints,
    attendancePoints,
    totalPoints,
    result,
    fouls,
    absentPoints
  };
};

// Update the code where calculatePoints is called to pass the player's name
const savePlayerDataToFirestore = async (mainGameId, TheGameId, playerData) => {
  const batch = writeBatch(database);

  console.log(`Saving player data for ${playerData.length} players:`, playerData);

  playerData.forEach((player) => {
    const team = teamSelections[player.userId]; // Use the latest team selection

    if (!player || !player.userId || !teamSelections[player.userId]) {
      console.error("Player or team not selected. Please select a team for the player.");
      return;
    }
    

    const playerRef = doc(
      database,
      "MainGame",
      mainGameId,
      "TheGame",
      TheGameId,
      "Players",
      player.userId
    );

    // Calculate the points for the player based on the latest team selection
    const { totalPoints } = calculatePoints(
      scoreHome,
      scoreAway,
      team, // Use the latest team
      gameSettings,
      player.fouls || 0,
      false, // isAbsent
      player.Name || "Unknown" // Pass player name for logging
    );

    // Save the points only if the totalPoints have changed or are new
    batch.set(
      playerRef,
      { points: totalPoints },
      { merge: true }
    );
  });

  const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
  batch.set(
    gameRef,
    {
      scoreHome: scoreHome,
      scoreAway: scoreAway,
    },
    { merge: true }
  );

  try {
    await batch.commit();
    console.log("All player data saved successfully.");
  } catch (error) {
    console.error("Error saving team data: ", error);
  }
};


const handleTeamSelection = (userId, team) => {
  // Update local state for team selections
  setTeamSelections((prev) => ({
    ...prev,
    [userId]: team,
  }));

  // Also update the Firestore database with the selected team
  updatePlayerTeam(userId, team);
  
  console.log(`Team selected for player ${userId}: ${team}`);
};


  
  

  // Update team selection when user selects Home or Away
// Update the team selection when user selects Home, Away, or Absent
// const updateTeamSelection = (userId, team) => {
//   setTeamSelections((prev) => ({
//     ...prev,
//     [userId]: team,
//   }));

//   console.log(`Team selected for player ${userId}: ${team}`);
// };


// Function to handle saving scores and teams
const handleSaveScores = async () => {
  try {
    // Check if all players have been assigned a team
    const playersWithoutTeam = playerData.filter((player) => !teamSelections[player.userId]);

    if (playersWithoutTeam.length > 0) {
      alert(`The following players do not have a team assigned: ${playersWithoutTeam.map(p => p.Name).join(', ')}. Please assign teams before saving.`);
      return; // Exit early if team assignment is incomplete
    }

    const userId = auth.currentUser.uid;
    const userName = await fetchUserName(userId);

    // Save player data (including team selections) to Firestore
    console.log("Saving player data to Firestore...");
    await savePlayerDataToFirestore(mainGameId, TheGameId, playerData);
    console.log("Player data saved successfully.");

    // Save game scores to Firestore
    console.log("Saving game scores to Firestore...");
    await saveGameScores(mainGameId, TheGameId, scoreHome, scoreAway, userId, userName);
    console.log("Game scores saved successfully.");

    // Call calculateAndStoreTotalPoints after saving game scores
    console.log("Calculating and storing total points...");
    await calculateAndStoreTotalPoints();
    console.log("Total points calculated and stored successfully.");

    // Notify user of success
   // alert("Scores and total points saved successfully.");
  } catch (error) {
    console.error("Error saving scores:", error);
    alert("An error occurred while saving scores. Please try again.");
  }
};



 


// find name from uid. 
const fetchUserName = async (userId) => {
  try {
    const userRef = doc(database, "users", userId); // Reference to the user's document in Firestore
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const { name } = userSnapshot.data(); // Extract the 'name' field from the user document
      return name;
    } else {
      console.error("No such user document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user name:", error);
    return null;
  }
};

  // Function to handle saving scores
  // Save scores to "TheGame" document
// Save scores to "TheGame" document along with additional stats like GD, GF, GA, etc.
const saveGameScores = async (mainGameId, TheGameId, scoreHome, scoreAway, userId, userName) => {
  const batch = writeBatch(database);

  try {
    playerData.forEach((player) => {
      // Ensure the player has a team before calculating points and stats
      if (!teamSelections[player.userId]) {
        console.error(`Player ${player.Name} does not have a team assigned.`);
        return; // Skip this player if no team is assigned
      }

      const playerTeam = teamSelections[player.userId]; // Use the team selection from state
      const playerRef = doc(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId,
        "Players",
        player.userId
      );

      // Calculate points based on the latest team selection
      const pointsResult = calculatePoints(scoreHome, scoreAway, playerTeam, gameSettings, player.fouls || 0, playerTeam === "Absent", player.Name || "Unknown");

      // Check if the calculation returned a valid result
      if (!pointsResult) {
        console.error(`Failed to calculate points for player ${player.Name}`);
        return; // Skip this player if points calculation failed
      }

      const { 
        totalPoints, 
        winPoints, 
        drawPoints, 
        losePoints, 
        attendancePoints, 
        teenGoalPoints, 
        result, 
        fouls,
        absentPoints
      } = pointsResult;

      // Calculate Goals For (GF), Goals Against (GA), and Goal Difference (GD)
      let gf = playerTeam === "Home" ? scoreHome : scoreAway;
      let ga = playerTeam === "Home" ? scoreAway : scoreHome;
      let gd = gf - ga;

      // Save points, GF, GA, GD, and other data to Firestore for each player
      batch.set(
        playerRef,
        {
          scoreHome,
          scoreAway,
          winPoints,
          drawPoints,
          losePoints,
          attendancePoints,
          teenGoalPoints,
          totalPoints,
          absentPoints,
          result,
          fouls: player.fouls || 0,
          gf,  // Goals For
          ga,  // Goals Against
          gd   // Goal Difference
        },
        { merge: true }
      );
    });

    // Save the overall game scores and metadata
    const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
    batch.set(
      gameRef,
      {
        scoreHome: scoreHome,
        scoreAway: scoreAway,
        savedBy: {
          userId: userId,
          name: userName,
        },
      },
      { merge: true }
    );

    // Commit the batch to Firestore
    await batch.commit();
    console.log("Game scores saved successfully.");

  } catch (error) {
    console.error("Error saving game scores:", error);
    throw new Error("Failed to save game scores to Firestore.");
  }
};

  
const calculateAndStoreTotalPoints = async () => {
  try {
    const gamesRef = collection(database, "MainGame", mainGameId, "TheGame");
    const gamesSnapshot = await getDocs(gamesRef);

    const gameDates = gamesSnapshot.docs
      .map((gameDoc) => ({
        gameId: gameDoc.id,
        gameDate: new Date(gameDoc.data().date),
      }))
      .sort((a, b) => a.gameDate - b.gameDate);

    // Loop through all games in chronological order and update each player's total points
    for (const { gameId: currentGameId } of gameDates) {
      const playersRef = collection(database, "MainGame", mainGameId, "TheGame", currentGameId, "Players");
      const playersSnapshot = await getDocs(playersRef);

      const playersData = playersSnapshot.docs.map((doc) => ({
        playerId: doc.id,
        ...doc.data(),
      }));

      // Calculate total points for each player across all games
      for (const player of playersData) {
        let totalPoints = 0;
        let previousTotalPoints = 0;

        // Loop through all games and accumulate points for the player
        for (const { gameId } of gameDates) {
          const playerRef = doc(database, "MainGame", mainGameId, "TheGame", gameId, "Players", player.playerId);
          const playerSnapshot = await getDoc(playerRef);
          const playerData = playerSnapshot.data();

          if (playerData && playerData.points != null) {
            totalPoints += playerData.points;

            // Store previous total points in the database
            if (playerData.totalPointsBefore !== previousTotalPoints) {
              await setDoc(
                playerRef,
                {
                  totalPointsBefore: previousTotalPoints,
                  newTotalPoints: totalPoints
                },
                { merge: true }
              );
            }
            previousTotalPoints = totalPoints;
          }
        }
      }
    }

    console.log("Total points calculated and stored for all players.");
  } catch (error) {
    console.error("Error calculating and storing total points:", error);
  }
};

  
  
  
  
  
  
  
  
  
  
  

  










  // Render each player row
  function RenderItem({ item }) {
    const teamSelection = teamSelections[item.userId] || null; // Default to null if not selected
  
    return (
      <View style={styles.playerRow}>
        <View style={{ flexDirection: "column", flexGrow: 1, marginLeft: 10 }}>
          <Text style={styles.playerName}>{item.Name}</Text>
          <Text style={styles.playerEmail}>{item.email}</Text>
        </View>
  
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Pressable
            onPress={() => handleTeamSelection(item.userId, "Home")}
            style={[
              styles.teamButton,
              teamSelection === "Home" && styles.teamSelected,
            ]}
          >
            <Text>Home</Text>
          </Pressable>
  
          <Pressable
            onPress={() => handleTeamSelection(item.userId, "Away")}
            style={[
              styles.teamButton,
              teamSelection === "Away" && styles.teamSelected,
            ]}
          >
            <Text>Away</Text>
          </Pressable>
  
          <Pressable
            onPress={() => handleTeamSelection(item.userId, "Absent")}
            style={[
              styles.teamButton,
              teamSelection === "Absent" && styles.teamSelected,
            ]}
          >
            <Text>Absent</Text>
          </Pressable>
        </View>
      </View>
    );
  }
  


  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* Show spinner if loading */}
      {loading && (
        <View style={styles.loadingOverlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}

      {/* Top buttons */}
      <View style={styles.topButtonsContainer}>
        <Pressable
          style={styles.topButton}
          onPress={clearAllTeams}
          disabled={loading}
        >
          <Text>Clear Teams</Text>
        </Pressable>

        <Pressable
          style={styles.topButton}
          onPress={assignAlternatingTeams}
          disabled={loading}
        >
          <Text>Toggle Teams</Text>
        </Pressable>

        <Pressable
          style={styles.topButton}
          onPress={randomizeAndSaveTeams}
          disabled={loading}
        >
          <Text>Random Teams</Text>               
        </Pressable>   
        <View>

        <View>
  {/* Pressable to open modal */}
  <Pressable
    style={styles.iconButton}
    onPress={() => setModalVisible(true)}
  >
    <MaterialIcons name="info" size={30} color="#2196F3" />
  </Pressable>

  {/* PointSystemModal component */}
  <PointSystemModal
  gameSettings={gameSettings}
  modalVisible={modalVisible}
  setModalVisible={setModalVisible}
  mainGameId={mainGameId}   // Pass mainGameId to the modal
  TheGameId={TheGameId}     // Pass TheGameId to the modal
/>

</View>





  {/* PointSystemModal component */}
  {/* <PointSystemModal gameSettings={gameSettings} /> */}
</View>
     
      </View>

      <View>          
  {/* <Text>
    {gameSettings && gameSettings.pointsEnabled !== undefined 
      ? `Points Enabled: ${gameSettings.pointsEnabled}` 
      : "Loading points settings..."}
  </Text> */}
</View>

      <View style={styles.header}>
        <Text>
          Name: {gameSettings?.name ?? "Loading..."} | Date:{" "}
          {moment(gameDate).format("DD MMMM YYYY")}
        </Text>
      </View>


  


 
{/* Save Scores Button Inside the Score Table */}
<View style={styles.scoreTable}>

<Pressable
  style={({ pressed }) => [
    styles.saveButton,
    pressed && styles.saveButtonPressed, // Apply the pressed style when button is pressed
  ]}
  onPress={() => {
    if (isAdmin) {
      // If admin, save the scores
      handleSaveScores();
    }
    // Navigate to the GameDashboard for both admin and non-admin users
    navigation.navigate("GameDashboard", {
      gameDate,
      mainGameId,
      TheGameId,
    });
  }}
  android_ripple={{ color: "#fff", radius: 200 }} // Ripple effect on Android
>
  <Text style={styles.saveButtonText}>
    {isAdmin ? "Save Live Scores" : "Show Live Scores"}
  </Text>
</Pressable>




{/* 
<Pressable
  style={({ pressed }) => [
    styles.saveButton,
    pressed && styles.saveButtonPressed, // Apply the pressed style when button is pressed
  ]}
  onPress={handleSaveScores}
  android_ripple={{ color: "#fff", radius: 200 }} // Ripple effect on Android
>
  <Text style={styles.saveButtonText}>Save Live Scores!</Text>
</Pressable> */}


  {/* Colorful Leaderboard Table */}
  <View style={styles.scoreTableRow}>
  <View style={styles.teamSection}>
    <Text style={styles.homeLabel}>Home Team</Text>
    <TextInput
      style={[styles.scoreInput]}
      keyboardType="numeric"
      placeholder="0"
      returnKeyType="done"
      placeholderTextColor="#000"
      value={scoreHome.toString()} // Bind the state and ensure it displays as a string
      onChangeText={(value) => isAdmin && setScoreHome(parseInt(value) || 0)} // Only allow changes if user is admin
      editable={isAdmin} // Make it non-editable for non-admins
    />
  </View>

  <Text style={styles.vsLabel}>vs</Text>

  <View style={styles.teamSection}>
    <Text style={styles.awayLabel}>Away Team</Text>
    <TextInput
      style={[styles.scoreInput]}
      keyboardType="numeric"
      placeholder="0"
      returnKeyType="done"
      placeholderTextColor="#000"
      value={scoreAway.toString()} // Bind the state and ensure it displays as a string
      onChangeText={(value) => isAdmin && setScoreAway(parseInt(value) || 0)} // Only allow changes if user is admin
      editable={isAdmin} // Make it non-editable for non-admins
    />
  </View>
</View>


</View>


      {/* Sorting buttons */}
      <View style={styles.sortButtonsContainer}>
        <Pressable
          style={[
            styles.sortButton,
            sortBy === "Rank"
              ? styles.sortButtonActive
              : styles.sortButtonInactive,
          ]}
          onPress={() => setSortBy("Rank")}
        >
          <Text style={styles.sortButtonText}>Sort by Rank</Text>
        </Pressable>

        <Pressable
          style={[
            styles.sortButton,
            sortBy === "Name"
              ? styles.sortButtonActive
              : styles.sortButtonInactive,
          ]}
          onPress={() => setSortBy("Name")}
        >
          <Text style={styles.sortButtonText}>Sort by Name</Text>
        </Pressable>
      </View>

{/* Display the total counter for all players and teams */}
{/* Updated counterContainer with flex properties for alignment */}
<View style={styles.counterContainer}>
  <Text 
    style={[
      styles.counterText, 
      { 
        width: 80, 
        color: (homeCount + awayCount + absentCount === playerData.length && playerData.length > 0) ? 'green' : 'red' 
      }
    ]}
  >
    Total:  {homeCount + awayCount + absentCount} / {playerData.length}
  </Text>  
  {/* <Text style={styles.counterText}>Home: {homeCount}</Text>
  <Text style={styles.counterText}>Away: {awayCount}</Text> */}

  <Text 
  style={[
    styles.counterText, 
    { 
      color: (homeCount === awayCount && playerData.length > 0) ? 'green' : 'orange' 
    }
  ]}
>
  Home:  {homeCount} 
</Text>

{/* <Text style={styles.counterText}>Total Selected: {homeCount + awayCount + absentCount}</Text> */}

<Text 
  style={[
    styles.counterText, 
    { 
      color: (homeCount === awayCount && playerData.length > 0) ? 'green' : 'orange' 
    }
  ]}
>
  Away: {awayCount}
</Text>
<Text style={styles.counterText}>Absent: {absentCount}</Text>





</View>


      

      

      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>




        <FlatList
          data={playerData}
          ListHeaderComponent={() => (
            <View style={styles.listHeader}>
              <Text style={styles.headerText}>Rank</Text>
              <Text style={[styles.headerText, { flex: 1 }]}>Name</Text>
              <Text style={styles.headerText}>Team</Text>
            </View>
          )}
          renderItem={({ item }) => (
            <RenderItem item={item} updatePlayerTeam={updatePlayerTeam} />
          )}
          keyExtractor={(item) => item.userId}
          contentContainerStyle={styles.flatListContent}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default Results;

const styles = StyleSheet.create({
  playerRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  rank: {
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 10,
  },
  playerName: {
    fontSize: 14,
  },
  playerEmail: {
    fontSize: 12,
    color: "#666",
  },
  teamButton: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: "#ddd",
    marginHorizontal: 5,
  },
  teamSelected: {
    backgroundColor: "#4C7BEE",
  },
  flatListContent: {
    paddingVertical: 10,
  },
  header: {
    padding: 10,
    backgroundColor: "#eee",
    alignItems: "center",
  },
  assignTeamsButton: {
    padding: 10,
    backgroundColor: "#4CAF50", // Green color for the button
    borderRadius: 8,
    margin: 10,
  },
  assignTeamsButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  clearTeamsButton: {
    padding: 10,
    backgroundColor: "#f44336", // Red color for the clear button
    borderRadius: 8,
    margin: 10,
  },
  clearTeamsButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  listHeader: {
    flexDirection: "row",
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: "#f0f0f0",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 16,
    width: 80, // Adjust width for Rank and Points
    textAlign: "left",
  },
  randomizeButton: {
    padding: 10,
    backgroundColor: "#FFA500", // Orange color for randomize button
    borderRadius: 8,
    margin: 10,
  },
  randomizeButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  topButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 5, // Reduce margin
  },
  topButton: {
    padding: 5, // Reduce padding
    backgroundColor: "#e8e8e8",
    borderRadius: 6, // Adjust border radius
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginHorizontal: 5, // Reduce horizontal margin
  },
  loadingOverlay: {
  position: "absolute", // Make the spinner overlay absolute
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
  zIndex: 10, // Ensure it's on top
  elevation: 10, // Android shadow (for elevation support)
},
  scoreboardContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 20,
    padding: 10,
    borderRadius: 15,
    backgroundColor: "#101820", // Dark background to match the image
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 8,
    elevation: 10, // Android shadow
  },
  teamContainer: {
    flex: 1,
    alignItems: "center",
    padding: 10,
    borderRadius: 10,
  },
  homeTeam: {
    backgroundColor: "#6a1b9a", // Purple-like color for home team
  },
  awayTeam: {
    backgroundColor: "#0288d1", // Blue-like color for away team
  },
  teamLogo: {
    width: 80,
    height: 80,
    marginBottom: 10,
  },
  teamName: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  scoreContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1e1e2f",
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderRadius: 10,
  },
  scoreText: {
    color: "#fff",
    fontSize: 50,
    fontWeight: "bold",
    marginHorizontal: 10,
  },
  timerText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    marginHorizontal: 10,
  },
 
  scoreTable: {
  borderWidth: 1,
  borderColor: "#ccc",
  borderRadius: 10,
  margin: 20,
  backgroundColor: "#27496D", // Dark background color

  // Existing shadow settings
  shadowColor: "#000",
  shadowOpacity: 0.3,
  shadowOffset: { width: 0, height: 2 }, 
  shadowRadius: 8,
  elevation: 10, // Android shadow

  // Add glow effect at the bottom
  shadowColor: "#4C7BEE", // Same color as your button for consistency
  shadowOffset: { width: 0, height: 6 }, // Strong shadow below the table
  shadowOpacity: 0.7, // Higher opacity for more visible glow
  shadowRadius: 5, // Larger radius for soft spread glow
  elevation: 15, // Ensure Android gets the effect too
},

  scoreTableRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  teamSection: {
    flexDirection: "column",
    alignItems: "center",
    marginHorizontal: 20,
  },
  homeLabel: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#4CAF50",
    textAlign: "center",
    marginBottom: 5,
  },
  awayLabel: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FF5722",
    textAlign: "center",
    marginBottom: 5,
  },
  vsLabel: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
    marginHorizontal: 20,
  },
  scoreInput: {
    width: 100,
    height: 40,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 8,
    textAlign: "center",
    fontSize: 24,
    color: "#000",
    backgroundColor: "#f0f0f0",
  },

  saveButtonPressed: {
  backgroundColor: "#3B68CC", // Darker shade of green when pressed
  transform: [{ scale: 0.98 }], // Slightly shrink the button to give a pressed effect
},

saveButton: {
  marginTop: -1,
  paddingVertical: 5, // Extreme padding for a bigger button
  paddingHorizontal: 35, 
  backgroundColor: "#4C7BEE", // Vibrant color for the button itself
  borderRadius: 15, // More rounded corners for a bold look
  alignItems: "center",
  justifyContent: "center",
  
  // Extreme shadow settings
  shadowColor: "#000",
  shadowOffset: { width: 0, height: 12 }, // Very large vertical shadow
  shadowOpacity: 0.9, // Almost opaque shadow
  shadowRadius: 15, // Wide, soft shadow
  elevation: 15, // High elevation for a deeper shadow on Android

  // Optional border and glow for a futuristic effect
  borderColor: "#fff",
  borderWidth: 2,
  shadowColor: "#00FFFF", // Neon-like glow effect
  shadowOffset: { width: 0, height: 0 }, // Shadow on all sides
  shadowOpacity: 0.7, // Visible shadow-glow mix
  shadowRadius: 25,
},
saveButtonText: {
  color: "#fff",
  fontSize: 15, // Larger font size for impact
  fontWeight: "bold",
  textTransform: "uppercase", // Keep uppercase for emphasis
  letterSpacing: 2, // Spacing between letters for a strong effect
},


  homeInput: {
    backgroundColor: "#E8F5E9", // Light green for Home score input
  },
  awayInput: {
    backgroundColor: "#FFEBEE", // Light red for Away score input
  },
  sortButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  sortButton: {
    padding: 10,
    backgroundColor: "#e8e8e8",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginHorizontal: 5,
  },

  sortButtonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  sortButtonActive: {
    backgroundColor: "#4C7BEE",
  },
  sortButtonInactive: {
    backgroundColor: "#ddd",
  },

  counterContainer: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    justifyContent: "space-between", // Aligns items horizontally with equal spacing
  },
  counterText: {
    fontSize: 12,
    color: "#555", // Softer color for better readability
  },

});
